import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import CommonGalleryContent from "../CommonGalleryContent";

const GaneshChaturthi = () => {
  // Get the asset base URL from the Redux store
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  // Get the Ganesh gallery data from the Redux store
  let Gallerylist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Ganesh,
  );

  // Limit the gallery list to 5 items if it exceeds that length
  if (Gallerylist && Gallerylist.length > 5) {
    Gallerylist = Gallerylist.slice(0, 5);
  }

  // Define the responsive breakpoints for the carousel
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 515 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 515, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <section className="gallery GaneshChaturthi">
        <div className="container mb-4-half">
          {/* Display the Ganesh Chaturthi badge */}
          <span className="badge badge-info">Ganesh Chaturthi</span>
          <div className="row">
            <div className="col-md-12">
              {/* Render the carousel with the gallery items */}
              <Carousel
                swipeable={true}
                draggable={false}
                infinite={false}
                showDots={false}
                responsive={responsive}
                autoPlaySpeed={5000000}
                transitionDuration={10}
                renderArrowsWhenDisabled={true}
                customTransition="transform 500ms ease"
                ssr={true}
                className="gallery-sec newgallery"
              >
                {Gallerylist?.length > 0 &&
                  Gallerylist?.map((els, idx) => (
                    <CommonGalleryContent
                      url={"ganesh-chaturthi/page-" + (idx + 1)}
                      src={`${assetbaseUrl?.value}${els?.media}`}
                      title={els?.title}
                    />
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GaneshChaturthi;
