import React, { useEffect } from "react";
import Astro from "../subcomponents/Astro.js";
import Gallery from "../subcomponents/Gallery.js";
import GallerySpotted from "../subcomponents/GallerySpotted.js";
import Gujarat from "../subcomponents/Gujarat.js";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { homePrimaryFetchData } from "../../redux/actions/homePrimaryAction";
import { homeSecondaryFetchData } from "../../redux/actions/homeSecondaryAction";
import HelperScript from "../../utils/helper";
import AdTwo from "../subcomponents/AdTwo.js";
import AdTwo_video from "../subcomponents/AdTwo_Video.js";
import AdTwo_photo from "../subcomponents/AdTwo_photo.js";
import Blogs from "../subcomponents/Blogs.js";
// import Election from "../subcomponents/Election.js";
import Entertainment from "../subcomponents/Entertainment.js";
import HomeTaboola from "../subcomponents/HomeTaboola.js";
import Lifestyle from "../subcomponents/Lifestyle.js";
import National from "../subcomponents/National.js";
import Sport from "../subcomponents/Sport.js";
import SubHome from "../subcomponents/SubHome.js";
import Trending from "../subcomponents/Trending.js";
import Videos from "../subcomponents/Videos.js";
import World from "../subcomponents/World.js";
import GaneshChaturthi from "../subcomponents/GaneshChaturthi.js";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(homePrimaryFetchData());
    dispatch(homeSecondaryFetchData());
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Gujarati News | Breaking News in Gujarati | Headline in Gujarati |
          Sandesh
        </title>
      </Helmet>
      <div className="ad-section">
        <HelperScript argSmall={188} argLarge={41} />
      </div>
      <div className="home-over-hide">
        <SubHome />

        {/* <Election /> */}
        <Gujarat />
        <GaneshChaturthi />
        <AdTwo />
        <National />
        <AdTwo_video />
        <Videos />
        <World />
        <GallerySpotted />
        <Trending />
        <AdTwo_photo />
        <Gallery />
        <Entertainment />
        <Blogs />
        <Sport />
        <Astro />
        <Lifestyle />
      </div>

      <HomeTaboola key={Math.random()} />
      <div className="ad-section">
        <HelperScript argSmall={192} argLarge={45} />
      </div>
    </>
  );
};

export default Home;
